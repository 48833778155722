import { splitProps, type ComponentProps } from "solid-js";
import { useTranslation } from "~/i18n";
import { useSession } from "~/session";
import { cx } from "~/utils";
import { PromptStylesReel } from "~/components/prompt-styles-reel";

type Props = ComponentProps<"div">;

export const IntroText = (props: Props) => {
  const [local] = splitProps(props, ["class"]);

  const [session] = useSession();
  const { t } = useTranslation(session.locale);

  const splittedText = t("home.welcome").split("{{ style }}");

  return (
    <div class={cx(["relative text-center", local.class])}>
      <span class="text-lg">{splittedText[0]}</span>
      <PromptStylesReel />
      <span class="text-lg">{splittedText[1]}</span>
    </div>
  );
};
