import { For, createEffect, onCleanup, createSignal, Show } from "solid-js";
import { useSession } from "~/session";
import { promptStyles } from "~/prompts";
import { useTranslation } from "~/i18n";

const HEIGHT = 34;
const TRANSITION_DURATION = 300;
const INTERVAL = 2000;

const PromptStyle = (props: { style: string }) => {
  return (
    <div class="flex items-center justify-center whitespace-nowrap text-center" style={{ height: `${HEIGHT}px` }}>
      <span class="text-md text-blue">{props.style}</span>
    </div>
  );
};

export const PromptStylesReel = () => {
  const [session] = useSession();
  const { t } = useTranslation(session.locale);
  const [activeIndex, setActiveIndex] = createSignal<number>(0);

  let scroller!: HTMLDivElement;
  let scrollTimeout: NodeJS.Timeout;
  let loopTimeout: NodeJS.Timeout;

  const styles = () => {
    // If a prompt style is selected, only show that style
    if (session.selectedPromptStyle) {
      const key = session.selectedPromptStyle.prompt;
      return [t(`style.${key}`)];
    } else {
      return [...new Set(Object.values(promptStyles).map((p) => t(`style.${p.prompt}`)))];
    }
  };

  const scrollToNextStyle = () => {
    const nextIndex = activeIndex() + 1;
    setActiveIndex(nextIndex);
    scroller.style.transform = `translateY(${nextIndex * -HEIGHT}px)`;
  };

  const loop = () => {
    // first and last items are duplicates to create a seamless loop
    // when the last item is reached, jump back to the first item
    setActiveIndex(0);
    scroller.style.transition = "none";
    scroller.style.transform = `translateY(0px)`;
    // restore transition after resetting to the first item
    setTimeout(() => {
      scroller.style.transition = `transform ${TRANSITION_DURATION}ms`;
    }, 100);
  };

  createEffect(() => {
    if (styles().length === 1) return;
    clearTimeout(scrollTimeout);
    scrollTimeout = setInterval(scrollToNextStyle, INTERVAL);
  });

  createEffect(() => {
    if (styles().length === 1) return;

    if (activeIndex() === styles().length) {
      // reset to the first item after the last item is reached
      loopTimeout = setTimeout(loop, TRANSITION_DURATION);
    }
  });

  onCleanup(() => {
    clearTimeout(scrollTimeout);
    clearTimeout(loopTimeout);
  });

  return (
    <div class="inline-block translate-y-2 overflow-hidden bg-blue-20 px-6" style={{ height: `${HEIGHT}px` }}>
      <div ref={scroller} class="relative flex flex-col transition-transform duration-300">
        <For each={styles()}>{(style) => <PromptStyle style={style} />}</For>

        {/* Duplicate first item to create a seamless loop */}
        <Show when={styles().length > 1}>
          <PromptStyle style={styles().at(0)!} />
        </Show>
      </div>
    </div>
  );
};
